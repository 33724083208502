import styles from "../Style/test.module.css"
import Webcam from "react-webcam";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Countdown from 'react-countdown';
import { api_url, test_duration } from "../../../config";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


function RightAside() {
    const navigate = useNavigate();
    const localUser = localStorage.getItem("user");

    const User = JSON.parse(localUser);
    const [capturedImage, setCapturedImage] = useState(null);
    let timePast = parseInt(localStorage.getItem("timePast"))
    if (!timePast) {
        timePast = 0;
    }
    const webcamRef = useRef(null); // Move useRef here to capturePicture scope

    const capturePicture = () => {
        console.log("func init");
        console.log(webcamRef);
        if (webcamRef.current) {
            console.log("Image Captured");
            const capturedImage = webcamRef.current.getScreenshot();
            setCapturedImage(capturedImage); // Set the captured image in state
            console.log(capturedImage);
            // Send the capturedImage to your desired link using axios or another HTTP library
            const formData = new FormData();
            const base64String = capturedImage;
            const base64Data = base64String.replace(/^data:image\/\w+;base64,/, '');
            formData.append("image", base64Data); // You may need to adjust this based on your API requirements
            formData.append("roll", User.data.user.applicationNumber);
            var json={image:base64Data,roll:User.data.user.applicationNumber}
            axios.post( api_url+"/users/save", json).then((response) => {
                // Handle the response if needed

                if (!response) {
                    // toast.error("Warning:Face Recognition Failed to verify You!", {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                }
            });
        }
    };

    useEffect(() => {
        // Capture a picture every minute (60,000 milliseconds)
        const captureInterval = setInterval(() => {
            capturePicture();
        }, 60000);

        return () => {
            // Clean up the interval when the component unmounts
            clearInterval(captureInterval);
        };
    }, []);


    const submitQuiz = async () => {

        const token = localStorage.getItem("jwt")

        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }

        const storedAnswers = JSON.parse(localStorage.getItem("answers"))
        const keys = Object.keys(storedAnswers)

        let finalAnswers = []
        for (let i = 0; i < keys.length; i++) {

            finalAnswers.push([keys[i], storedAnswers[keys[i]]])
        }


        const data = {
            "questionIdsAndAnswers": finalAnswers,
        }

        const res = await axios.post(api_url + "/answers/checkAnswers", data, config)

        if (res.data.status == "success") {
            localStorage.removeItem("jwt")
            localStorage.removeItem("user")
            localStorage.removeItem("answers")
            localStorage.removeItem("timePast")
            document.exitFullscreen()
            navigate("/submitted")
        }
    }

    const timerTick = () => {
        timePast += 1000;
        localStorage.setItem("timePast", timePast)
    }

    return (
        <div>
            <ToastContainer />
            <div className={styles.testTimer}>

                <Countdown date={Date.now() + test_duration * 60 * 1000 - timePast} onComplete={submitQuiz} onTick={timerTick} className={styles.testTime} />

                <div className={styles.timerText}>TIME REMAINING</div>
            </div>
            <Webcam ref={webcamRef} className={styles.testWebcam} />
        </div>
    )
}

export default RightAside